<template>
	<div class="wrapper-accordion">
		<TnAccordion
			:multiple="!component.content.single"
			:first-item-open="component.content.firstItemOpen"
			:dark="dark"
		>
			<TnAccordionItem
				v-for="(item, index) in items"
				v-bind="item"
				:key="`accordion-item-${index}`"
				:class="{ 'accordion-white-bg': $attrs.hasBackground && !dark }"
				:size="$store.getters.isMobile ? 's' : 'm'"
				:dark="dark"
			>
				<!-- Icon -->
				<template
					v-if="!$store.getters.isMobile"
					#icon
				>
					<img
						v-if="item['iconOrImage'] && item['iconOrImage'].hasOwnProperty('icon')"
						v-bind="item['iconOrImage'].icon"
					/>
					<TnIcon
						v-else-if="item['iconOrImage'] && item['iconOrImage'].hasOwnProperty('iconUI')"
						:name="item['iconOrImage']['iconUI']"
						size="l"
					/>
				</template>

				<!-- Content -->

				<TnNotificationInline
					v-if="item.infoBox"
					:dark="dark"
					class="margin-bottom-m infobox"
				>
					<RichText :text="item.infoBox" />
				</TnNotificationInline>

				<RichText
					:text="item.content"
					:dark="dark"
				/>
				<Component
					v-if="item.linkedComponent"
					class="margin-top-m"
					:component="item.linkedComponent"
					:is="getComponentName(item.linkedComponent)"
				></Component>
				<TnButton
					v-if="item.button"
					v-bind="item.button"
					@click="$emit('click', item)"
					class="margin-top-m"
					:dark="dark"
				></TnButton>
			</TnAccordionItem>
		</TnAccordion>
	</div>
</template>

<script>
import ButtonViewModel from "~/helpers/ViewModels/ButtonViewModel.js";
import GizmoComponentMixin from "~/mixins/GizmoComponentMixin.js";
import getComponentName from "~/helpers/gizmo/getComponentName.js";

export default defineNuxtComponent({
	/**
	 * Wrapper component for TnAccordion for telenor.no
	 *    schema Test: https://cms.test-dc.corp.telenor.no/portal/telenor.no/_schemas/telenor.no-component-accordion
	 */
	name: "Accordion",

	mixins: [GizmoComponentMixin],

	computed: {
		items() {
			let items = [];
			if (this.component.content.accordionItems) {
				items = JSON.parse(JSON.stringify(this.component.content.accordionItems));
			}

			return items.map((item) => {
				if (item.icon) {
					item["iconOrImage"] = {};
					item["iconOrImage"].icon = item.icon;
					delete item.icon;
				}
				if (item.ctaButton) item.button = ButtonViewModel(item.ctaButton);
				return item;
			});
		},
	},

	methods: {
		faqDataJsonld() {
			if (this.component.content?.enableFAQmarkup === true) {
				const jsonldScript = document.createElement("script");
				jsonldScript.setAttribute("data-n-head", "ssr");
				jsonldScript.setAttribute("type", "application/ld+json");

				const structuredData = {
					"@context": "https://schema.org",
					"@type": "FAQPage",
					mainEntity: this.items?.map((tab) => ({
						"@type": "Question",
						name: tab?.title,
						acceptedAnswer: {
							"@type": "Answer",
							text: tab?.content.replace(/<[^>]+>|&nbsp;/g, ""),
						},
					})),
				};
				jsonldScript.textContent = JSON.stringify(structuredData);

				return document.head.appendChild(jsonldScript);
			}
		},
		getComponentName(component) {
			return getComponentName(component);
		},
	},

	mounted() {
		window.addEventListener("load", this.faqDataJsonld);
	},

	unmounted() {
		window.removeEventListener("load", this.faqDataJsonld);
	},
});
</script>

<style lang="scss" scoped>
.wrapper-accordion {
	margin: 0 auto;

	.accordion-white-bg {
		background-color: $color-background-white !important;
	}
}

.infobox {
	font-size: inherit;
}

.button {
	margin-top: $spacing-xl;
}

.accordion-item-heading > .left-col-icon > img {
	max-height: 48px;
	max-width: 48px;
	object-fit: contain;
	display: block;
	margin: 0 auto;
}
</style>
